<template>
  <el-dialog
    v-model="dialogVisible"
    :destroy-on-close="true"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :show-close="false"
    :title="$t.translate('TTL_EXPORT_DOCUMENT')"
    width="300px"
  >
    <div class="mb-3">
      <label class="form-label">{{ $t.translate("LBL_EXPORT_TYPE") }}</label>
      <div class="block form-group">
        <template v-for="item in TypeList" :key="item">
          <el-radio :disabled="exporting" v-model="formData.Mode" :label="item">{{
            $t.translate(`LBL_${item}_FILE`)
          }}</el-radio>
        </template>
      </div>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button :disabled="exporting" @click="dialogVisible = false">{{
          $t.translate("BTN_CANCEL")
        }}</el-button>
        <el-button type="primary" @click="confirm" :loading="exporting">{{
          $t.translate("BTN_EXPORT")
        }}</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-model="downloadDialogVisible"
    :destroy-on-close="true"
    :title="$t.translate('TTL_EXPORT_DOCUMENT')"
  >
    <div class="mb-3">
      <label class="form-label">{{
        $t.translate("LBL_CLICK_LINK_BELOW_TO_DOWNLOAD")
      }}</label>
      <div class="block form-group">
        <el-table :empty-text="$t.translate('LBL_NO_DATA')" :data="links">
          <el-table-column :label="$t.translate('FRM_FILE')">
            <template #default="scope">
              {{ scope.row.FileName }}
            </template>
          </el-table-column>

          <el-table-column :label="$t.translate('TH_ACTION')" fixed="right" width="120">
            <template #default="scope">
              <a :href="scope.row.Link" target="_blank">
                <el-button class="w-100" type="primary">{{
                  $t.translate("BTN_DOWNLOAD")
                }}</el-button>
              </a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button :disabled="exporting" @click="downloadDialogVisible = false">{{
          $t.translate("BTN_DONE")
        }}</el-button>

        <a
          style="margin-left: 10px"
          v-if="DownloadAll"
          :href="$axios.formulateUrl('/export/download?Token=' + DownloadAll)"
          target="_blank"
        >
          <el-button type="primary">{{
            $t.translate("LBL_DOWNLOAD_ALL_AS_ZIP")
          }}</el-button>
        </a>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { ref, reactive, onMounted, computed } from "vue";
import { formulateUrl, get, post, isValidResponse } from "@/utils/axios";
import { store } from "@/store";
import _ from "lodash";

export default {
  props: {
    types: Array,
  },
  setup(props) {
    let formData = reactive({
      Mode: "EXCEL",
      Export: {},
    });

    let TypeList = computed(() => {
      if (formData?.Export?.ExcelOnly) {
        return ["EXCEL"];
      }
      if (formData?.Export?.PDFOnly) {
        return ["PDF"];
      }
      return props.types || [];
    });

    let dialogVisible = ref(false);
    let downloadDialogVisible = ref(false);
    let exporting = ref(false);
    let links = ref([]);
    let DownloadAll = ref(null);
    let zipping = ref(false);

    let confirm = async () => {
      if (exporting.value === true) return;

      exporting.value = true;
      let res = await post(
        formData?.Export?.Public ? "/export/document/public" : "/export/document",
        formData
      );
      exporting.value = false;

      if (isValidResponse(res)) {
        //redirect
        dialogVisible.value = false;

        links.value = _.map(res.Links, (r) => {
          return {
            Link: formulateUrl("/export/download?Token=" + r.DownloadToken),
            FileName: r.FileName,
          };
        });

        DownloadAll.value = res?.DownloadAll || null;
        downloadDialogVisible.value = true;
      }
    };

    let exportContent = async (payload) => {
      formData.Export = payload;

      if (!(TypeList.value.indexOf(formData.Mode) > -1)) {
        formData.Mode = TypeList.value[0];
      }

      if (TypeList.value.length > 1) {
        dialogVisible.value = true;
      } else {
        //export immediately
        await confirm();
      }
    };

    return {
      DownloadAll,
      zipping,
      dialogVisible,
      downloadDialogVisible,
      links,
      exporting,
      confirm,
      exportContent,
      TypeList,
      formData,
    };
  },
};
</script>

<template>
  <div class="page-custom page-independent container-fluid pt-5 pb-5">
    <div class="container mt-2">
      <div class="row">
        <div class="col-xl-1"></div>
        <div v-if="loaded" class="col-xl-10 order-xl-1">
          <card shadow type="secondary mb-4">
            <template #header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-12 d-flex justify-content-between">
                    <h2 class="mb-0">
                      {{ $t.translate("LBL_PAYMENT_DETAILS") }}
                    </h2>
                    <el-tag
                      :type="!Application.IsPaid ? 'warning' : 'success'"
                      effect="dark"
                      disable-transitions
                    >
                      {{ $t.translate(Application.PaymentLabel) }}</el-tag
                    >
                  </div>
                </div>
              </div>
            </template>

            <form>
              <div class="pl-lg-4 pr-lg-4">
                <div
                  v-if="Application.CertificateKey"
                  class="row justify-content-between"
                >
                  <div class="col-md-6">
                    <p>{{ $t.translate("FRM_CERT_STAT_NO") }}</p>
                  </div>
                  <div class="col-md-6 text-md-right">
                    <p>{{ Application.CertificateNo }}</p>
                  </div>
                </div>
                <el-divider v-if="Application.CertificateKey" class="mt-0 mb-3" />

                <div
                  v-if="Application?.ApplicationNo"
                  class="row justify-content-between"
                >
                  <div class="col-md-6">
                    <p>{{ $t.translate("LBL_APPLICATION_NO") }}</p>
                  </div>
                  <div class="col-md-6 text-md-right">
                    <p>{{ Application.ApplicationNo }}</p>
                  </div>
                </div>
                <el-divider v-if="Application?.ApplicationNo" class="mt-0 mb-3" />

                <div class="row justify-content-between">
                  <div class="col-md-6">
                    <p>{{ $t.translate("FRM_ASSESSMENT_SERVICE_NAME") }}</p>
                  </div>
                  <div class="col-md-6 text-md-right">
                    <p>
                      {{ $h.formatName(Application, "ServiceName") }}
                      <span v-if="$h.formatName(Application, 'ServiceNameExtra', true)">
                        - {{ $h.formatName(Application, "ServiceNameExtra") }}</span
                      >
                    </p>
                  </div>
                </div>
                <el-divider class="mt-0 mb-3" />

                <div class="row justify-content-between">
                  <div class="col-md-6">
                    <p>{{ $t.translate("FRM_APPLICATION_FEE") }}</p>
                  </div>
                  <div class="col-md-6 text-md-right">
                    <p>
                      <b
                        >{{ Application.CurrencyLabel.label }}
                        {{ $h.formatPrice(Application.ApplicationFee) }}</b
                      >
                    </p>
                  </div>
                </div>
                <el-divider class="mt-0 mb-3" />

                <div class="row justify-content-between">
                  <div class="col-md-6">
                    <p>{{ $t.translate("FRM_ASSESSMENT_FEE") }}</p>
                  </div>
                  <div class="col-md-6 text-md-right">
                    <p>
                      <b
                        >{{ Application.CurrencyLabel.label }}
                        {{ $h.formatPrice(Application.AssessmentFee) }}</b
                      >
                    </p>
                  </div>
                </div>
                <el-divider class="mt-0 mb-3" />

                <div class="row justify-content-between">
                  <div class="col-md-6">
                    <p>{{ $t.translate("LBL_TOTAL_COST") }}</p>
                  </div>
                  <div class="col-md-6 text-md-right">
                    <p>
                      <b
                        >{{ Application.CurrencyLabel.label }}
                        {{ $h.formatPrice(Application.TotalCost) }}</b
                      >
                    </p>
                  </div>
                </div>
                <el-divider class="mt-0 mb-3" />

                <!-- <div class="row justify-content-between">
                  <div class="col-md-6">
                    <p>{{ $t.translate("LBL_BALANCE_DUE") }}</p>
                  </div>
                  <div class="col-md-6 text-md-right">
                    <p>
                      <b
                        >{{ Application.CurrencyLabel }}
                        {{
                          Application.Invoice.TotalCost - Application.Invoice.TotalPaid
                        }}</b
                      >
                    </p>
                  </div>
                </div> -->
                <!-- <el-divider class="mt-0 mb-3" /> -->

                <template v-if="Application?.PaymentProof?.Attachment">
                  <div class="row justify-content-between">
                    <div class="col-md-6">
                      <p>{{ $t.translate("LBL_PAYMENT_PROOF") }}</p>
                    </div>
                    <div class="col-md-6 text-md-right">
                      <a
                        class="el-button el-button--text"
                        :href="
                          $axios.formulateUrl(
                            '/export/download?Token=' +
                              Application?.PaymentProof?.DownloadToken
                          )
                        "
                      >
                        <i class="c-pointer fas fa-eye"></i>
                      </a>
                    </div>
                  </div>
                  <el-divider class="mt-0 mb-3" />

                  <div class="row justify-content-between">
                    <div class="col-md-6">
                      <p>{{ $t.translate("LBL_UPLOAD_DATE_TIME") }}</p>
                    </div>
                    <div class="col-md-6 text-md-right">
                      <p>{{ $h.formatDateTime(Application.PaymentProof.LastCreated) }}</p>
                    </div>
                  </div>

                  <el-divider class="mt-0 mb-3" />
                </template>

                <div class="row justify-content-between">
                  <div class="col-md-6">
                    <p>{{ $t.translate("LBL_PAYMENT_DATE_BY_CLIENT") }}</p>
                  </div>
                  <div class="col-md-6 text-md-right">
                    <p>{{ $h.formatDate(Application.SettlementDateTime) }}</p>
                  </div>
                </div>

                <el-divider class="mt-0 mb-3" />

                <div class="row justify-content-between">
                  <div class="col-md-6">
                    <p>{{ $t.translate("LBL_PAYMENT_DATE_FINANCE") }}</p>
                  </div>
                  <div class="col-md-6 text-md-right">
                    <p>{{ $h.formatDate(Application.PaymentDateTime) }}</p>
                  </div>
                </div>

                <template v-if="Application.OnlineInvoice">
                  <el-divider class="mt-0 mb-3" />

                  <div class="row justify-content-between">
                    <div class="col-md-6">
                      <p class="mb-0">{{ $t.translate("LBL_PAYMENT_MODE") }}</p>
                    </div>
                    <div
                      class="col-md-6 d-flex"
                      style="flex-direction: column; align-items: flex-end"
                    >
                      <div class="payment-method">
                        <div
                          :style="{
                            'background-image': `url('/img/payment/${Application.OnlineInvoice.PayTypeInfo.logo}')`,
                          }"
                        ></div>

                        <div
                          v-if="Application?.OnlineInvoice?.PayTypeInfo?.altlogo"
                          :style="{
                            'background-image': `url('/img/payment/${Application.OnlineInvoice.PayTypeInfo.altlogo}')`,
                          }"
                        ></div>

                        {{ $t.translate(Application.OnlineInvoice.PayTypeInfo.label) }}
                      </div>

                      <span class="text-muted"
                        >{{ $t.translate("LBL_TRANX_REFERENCE") }}:
                        {{
                          Application.OnlineInvoice?.Payment?.SubOrderId ||
                          Application.OnlineInvoice?.TransactionReference
                        }}</span
                      >
                      <span class="text-muted"
                        >{{ $t.translate("LBL_TRANX_DATE") }}:
                        {{
                          $h.formatDateTime(Application.OnlineInvoice.PaymentDate)
                        }}</span
                      >
                    </div>
                  </div>
                </template>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>

  <div class="nav-bottom">
    <div></div>
    <div class="d-flex">
      <el-button type="default" class="mr-4 d-none d-md-block" @click="$r.goBack">
        {{ $t.translate("BTN_BACK") }}
      </el-button>
      <el-button
        type="primary"
        class=""
        @click="
          $refs.export.exportContent({
            Mode: 'GENERATE_INVOICE',
            Filter: {
              ApplicationKey: Application.ApplicationKey,
            },
            PDFOnly: true,
          })
        "
      >
        {{ $t.translate("BTN_VIEW_INVOICE") }}
      </el-button>
    </div>
  </div>

  <ExportModal :types="['PDF']" ref="export" />
</template>
<script>
import ExportModal from "@/components/ExportModal";
import { useRoute } from "vue-router";
import _ from "lodash";
import { onMounted, ref } from "vue";
import { get } from "@/utils/axios";

export default {
  components: { ExportModal },
  setup() {
    const loaded = ref(false);
    const route = useRoute();

    let Application = ref({});

    onMounted(async () => {
      if (route.path.indexOf("/certificate/invoice") > -1) {
        let info = await get("/application/certificate/view", {
          CertificateKey: route.params.key,
        });
        Application.value = info?.Certificate;
      } else {
        let info = await get("/application/view", { ApplicationKey: route.params.key });
        Application.value = info?.Application;
      }
      loaded.value = true;
    });

    return {
      loaded,
      Application,
    };
  },
};
</script>
<style scoped>
.payment-method > div {
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.payment-method {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-bottom: 5px;
}

.text-muted {
  font-size: 11px;
}
</style>
